import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpEvent, HttpErrorResponse, HttpEventType } from "@angular/common/http";
import { Observable, of } from 'rxjs';
import { map } from "rxjs/operators";
import { environment } from 'environments/environment';
import { v4 as uuid } from 'uuid';
import { MeterType } from './meter-type';

@Injectable({
	providedIn: 'root'
})

export class MeterTypesService {
	private extractData(res: Response) {
		let body = res;
		return body || {};
	}

	meterTypesUrl = environment.tokenUrl;

	constructor(private http: HttpClient) { }

	private baseUrl = this.meterTypesUrl;

	private httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json'
		})
	};


	public allMeterTypes(): Observable<any> {
		return this.http.get(`${this.baseUrl}/meter-types`);
	}

	/**
	 * getMeterType
	 */
	public getMeterType(id: string): Observable<any> {
		return this.http.get(`${this.baseUrl}/meter-type/?id=${id}`);
	}

	/**
	 * addMeterType
	 */
	public addMeterType(value: any) : Observable<any>  {
		return this.http.post(`${this.baseUrl}/add-meter-type/`, value);
	}

	/**
	 * updateMeterType
	 */
	public updateMeterType(value: any) : Observable<any>  {
		return this.http.post(`${this.baseUrl}/update-meter-type/`, value);
	}
	/**
	 * deleteMeterType
	 */
	public deleteMeterType(value: any): Observable<any>  {
		return this.http.post(`${this.baseUrl}/delete-meter-type/`, value);
	}

	changePassword(value:any): Observable<any> {
		return this.http.post(`${this.baseUrl}/change-password/`, value);
	}
}
