import {Component, Inject, OnInit} from '@angular/core';
// import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { VendorService } from '../vendor.service';
import { NotificationService } from 'app/_services/notification.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  templateUrl:'add-vendor-dialog.component.html',
  styleUrls:['add-vendor-dialog.component.scss'
  ]
})
export class AddVendorDialogComponent {

    vendorForm: FormGroup;
    loading = false;

    constructor(
        private formBuilder: FormBuilder,
        private dialogRef: MatDialogRef<AddVendorDialogComponent>,
        private vendorsService: VendorService,
        public notificationService: NotificationService,
    ) {}

    ngOnInit() {

        this.vendorForm = this.formBuilder.group({
          companyName: ['', [Validators.required]],
          contactPerson: ['', [Validators.required]],
          contactPersonNumber: ['', [Validators.required,Validators.pattern("^((\\+91-?))?[0-9]{9}$")]],
          companyEmail: ['', [Validators.required]]
        });

        // this.vendorForm.patchValue({
        //     role: this.roles ? this.roles[0] : 'None'
        // });
    }

    onCloseDialog() {
        this.dialogRef.close();
    }

    onSubmit(value) {

      // value.uid = this.generateRandomString(15);

        console.log("Vendor value ");
        console.log(value);

        // console.log("Vendor Password");
        // console.log(this.generatePassword(4));
        this.loading = true;
        
        this.vendorsService.addVendor(value)
          .subscribe(
            data => {
              console.log("Server response: ");
              console.log(data);
              this.loading = false;
              this.notificationService.openSnackBar(data.message, 'Close', 'success-snackbar', 10000);
            },
            error => {
              this.loading = false;
              console.log(error);
              this.notificationService.openSnackBar(error.message, 'Close', 'error-snackbar', 10000);
            });

      this.dialogRef.close();
    }

    generatePassword(passwordLength) {
        var numberChars = "0123456789";
        var upperChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        var lowerChars = "abcdefghijklmnopqrstuvwxyz";
        var allChars = numberChars + upperChars + lowerChars;
        var randPasswordArray = Array(passwordLength);
        randPasswordArray[0] = numberChars;
        randPasswordArray[1] = upperChars;
        randPasswordArray[2] = lowerChars;
        randPasswordArray = randPasswordArray.fill(allChars, 3);
        return this.shuffleArray(randPasswordArray.map(function(x) { return x[Math.floor(Math.random() * x.length)] })).join('');
      }
      
      shuffleArray(array) {
        for (var i = array.length - 1; i > 0; i--) {
          var j = Math.floor(Math.random() * (i + 1));
          var temp = array[i];
          array[i] = array[j];
          array[j] = temp;
        }
        return array;
      }

  generateRandomString(length: number): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  }
      


}
