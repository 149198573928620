import { Component, Inject, OnInit } from "@angular/core";
// import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { Vendor } from "../vendor";
import { MeterTypesService } from "../meter-types.service";
import { NotificationService } from "app/_services/notification.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Observable } from "rxjs";
import { VendorService } from "../../vendors/vendor.service";

@Component({
  templateUrl: "add-meter-type-dialog.component.html",
  styleUrls: ["add-meter-type-dialog.component.scss",
  ],
})
export class AddMeterTypeDialogComponent {

  meterTypeForm: FormGroup;
  vendors: Vendor[];
  loading = false;

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<AddMeterTypeDialogComponent>,
    private meterTypesService: MeterTypesService,
    private vendorsTypesService: VendorService,
    public notificationService: NotificationService,
  ) {
  }

  ngOnInit() {

    // this.vendors = [{"id": "1", "name":"Moderator"},{"id": "2", "name":"Administrator"}];
    this.vendorsTypesService.allVendors().subscribe(value => {
      console.log("Observable vendors.");
      console.log(value);
      this.vendors = value.data;
    });

    this.meterTypeForm = this.formBuilder.group({
      meterName: ["", [Validators.required]],
      tokenUrl: ["", [Validators.required]],
      platformId: ["", [Validators.required]],
      platformName: ["", [Validators.required]],
      platformPassword: ["", [Validators.required]],
      vendor: ["", [Validators.required]],
    });

    this.meterTypeForm.patchValue({
      vendor: this.vendors ? this.vendors[0] : "None",
    });
  }

  onCloseDialog() {
    this.dialogRef.close();
  }

  onSubmit(value) {

    value.vendor = value.vendor.id;

    console.log("MeterType value ");
    console.log(value);

    // console.log("MeterType Password");
    // console.log(this.generatePassword(4));
    this.loading = true;

    this.meterTypesService.addMeterType(value)
      .subscribe(
        data => {
          console.log("Server response: ");
          console.log(data);
          this.loading = false;
          this.notificationService.openSnackBar(data.message, "Close", "success-snackbar", 10000);
        },
        error => {
          this.loading = false;
          console.log(error);
          this.notificationService.openSnackBar(error.message, "Close", "error-snackbar", 10000);
        });

    this.dialogRef.close();
  }

  generatePassword(passwordLength) {
    var numberChars = "0123456789";
    var upperChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var lowerChars = "abcdefghijklmnopqrstuvwxyz";
    var allChars = numberChars + upperChars + lowerChars;
    var randPasswordArray = Array(passwordLength);
    randPasswordArray[0] = numberChars;
    randPasswordArray[1] = upperChars;
    randPasswordArray[2] = lowerChars;
    randPasswordArray = randPasswordArray.fill(allChars, 3);
    return this.shuffleArray(randPasswordArray.map(function(x) {
      return x[Math.floor(Math.random() * x.length)];
    })).join("");
  }

  shuffleArray(array) {
    for (var i = array.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1));
      var temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  }
}
