import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpEvent, HttpErrorResponse, HttpEventType } from "@angular/common/http";
import { Observable, of } from 'rxjs';
import { map } from "rxjs/operators";
import { environment } from 'environments/environment';
import { v4 as uuid } from 'uuid';
import { Vendor } from './vendor';

@Injectable({
	providedIn: 'root'
})

export class VendorService {
	private extractData(res: Response) {
		let body = res;
		return body || {};
	}

	vendorsUrl = environment.tokenUrl;

	constructor(private http: HttpClient) { }

	private baseUrl = this.vendorsUrl;

	private httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json'
		})
	};


	public allVendors(): Observable<any> {
		return this.http.get(`${this.baseUrl}/all-vendors`);
	}

	/**
	 * getVendor
	 */
	public getVendor(id: string): Observable<any> {
		return this.http.get(`${this.baseUrl}/vendor/?id=${id}`);
	}

	/**
	 * addVendor
	 */
	public addVendor(value: any) : Observable<any>  {
		return this.http.post(`${this.baseUrl}/add-vendor/`, value);
	}

	/**
	 * updateVendor
	 */
	public updateVendor(value: any) : Observable<any>  {
		return this.http.post(`${this.baseUrl}/update-vendor/`, value);
	}
	/**
	 * deleteVendor
	 */
	public deleteVendor(value: any): Observable<any>  {
		return this.http.post(`${this.baseUrl}/delete-vendor/`, value);
	}

	changePassword(value:any): Observable<any> {
		return this.http.post(`${this.baseUrl}/change-password/`, value);
	}
}
