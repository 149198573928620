import { Component, Inject, OnInit } from '@angular/core';
// import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from 'app/_services/notification.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { VendorService } from 'app/vendors/vendor.service';
import { MetersService } from '../meters.service';
import { MeterTypesService } from 'app/meter-types/meter-types.service';

@Component({
  templateUrl: 'add-meter-dialog.component.html',
  styleUrls: ['add-meter-dialog.component.scss'
  ]
})
export class AddMterDialogComponent {

  meterForm: FormGroup;
  loading = false;
  meterTypes:[] = [];

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<AddMterDialogComponent>,
    private meterService: MetersService,
    public notificationService: NotificationService,
    private meterTypesService: MeterTypesService,
  ) { }

  ngOnInit(): void {
    this.meterForm = this.fb.group({
      msisdn: ['', [Validators.required, Validators.pattern('^[0-9]{10,12}$')]],
      meter: ['', [Validators.required, Validators.minLength(11), Validators.maxLength(13)]],
      meterTypeId: ['', Validators.required],
      metername:[],
      customername: ['', Validators.required],
    });
    this.getMeterTypes()
  }

  getMeterTypes(){
    this.meterTypesService.allMeterTypes().subscribe({next:resp=>{
      if(resp.status == 'TPDCS'){
        this.meterTypes = resp.data;
      }
    },error:(error)=>{

    }});
  }

  onCloseDialog() {
    this.dialogRef.close();
  }

  onSubmit(): void {

    if (this.meterForm.valid) {
      const meterData = {
        ...this.meterForm.value,
        meterTypeItemId: '1'
      };
      console.log('Meter Data:', meterData);

      this.loading = true;

      this.meterService.registerMeter(meterData)
        .subscribe(
          data => {
            console.log("Server response: ");
            console.log(data);
            this.loading = false;
            this.notificationService.openSnackBar(data.message, 'Close', 'success-snackbar', 40000);
          },
          error => {
            this.loading = false;
            console.log(error);
            this.notificationService.openSnackBar(error.message, 'Close', 'error-snackbar', 10000);
          });

      this.dialogRef.close();


      alert('Meter added successfully');
    }


  }

  generatePassword(passwordLength) {
    var numberChars = "0123456789";
    var upperChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var lowerChars = "abcdefghijklmnopqrstuvwxyz";
    var allChars = numberChars + upperChars + lowerChars;
    var randPasswordArray = Array(passwordLength);
    randPasswordArray[0] = numberChars;
    randPasswordArray[1] = upperChars;
    randPasswordArray[2] = lowerChars;
    randPasswordArray = randPasswordArray.fill(allChars, 3);
    return this.shuffleArray(randPasswordArray.map(function (x) { return x[Math.floor(Math.random() * x.length)] })).join('');
  }

  shuffleArray(array) {
    for (var i = array.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1));
      var temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  }

  generateRandomString(length: number): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  }



}
