import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {AuthenticationService} from '../../_services';
import {User} from '../../_models';

declare const $: any;

declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}

export let ROUTES: RouteInfo[] = [
    {path: '/dashboard', title: 'Dashboard', icon: 'dashboard', class: ''},
    {path: '/transactions', title: 'Transactions', icon: 'library_books', class: ''},
    {path: '/meters', title: 'Meters', icon: 'library_books', class: ''},
    {path: '/reconciliation', title: 'Reconciliation', icon: 'library_books', class: ''},
    {path: '/reports', title: 'Reports', icon: 'library_books', class: ''},
];
export const ROUTES1: RouteInfo[] = [
    {path: '/dashboard', title: 'Dashboard', icon: 'dashboard', class: ''},
    {path: '/transactions', title: 'Transactions', icon: 'library_books', class: ''},
    {path: '/meters', title: 'Meters', icon: 'library_books', class: ''},
    {path: '/reconciliation', title: 'Reconciliation', icon: 'library_books', class: ''},
    {path: '/reports', title: 'Reports', icon: 'library_books', class: ''},
    {path: '/meter-types', title: 'Meter Types', icon: 'library_books', class: ''},
    {path: '/vendors', title: 'Vendors', icon: 'person', class: ''},
    {path: '/users', title: 'Users', icon: 'person', class: ''},
];

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
    menuItems: any[];
    currentUser: User;

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) {
        this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    }

    ngOnInit() {
        if (this.currentUser != null && this.currentUser.roles[0].toString() == 'ADMIN'){
            console.log("ADMIN ROLE")
            this.menuItems = ROUTES1.filter(menuItem => menuItem);
            console.log("ADMIN ROLE")
        } else {
            this.menuItems = ROUTES.filter(menuItem => menuItem);
        }
    }

    isMobileMenu() {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    };

    logout() {
        this.authenticationService.logout();
        this.router.navigate(['/login']);
    }
}
